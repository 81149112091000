<template>
  <div class="blog-post">
    <Navbar/>  
    <div class="container-fluid">
      <SubNav>
        <template #link1>
          <router-link to="/blog"> 
            <u>Blog</u>>
          </router-link>
        </template>
        <template #current>
          Система образования во Франции
        </template>
      </SubNav>
      <div class="row">
        <div class="col-12 offset-lg-3 col-lg-6">
          <PostHeader>
            <template #title>
                Система образования во Франции
            </template>
            <template #date>
                07.05.2021
            </template>
          </PostHeader>

          <PostBodyIntroduction>
              <template #introduction>
                  <p>
                      Что нужно знать о среднем образовании во Франции.
                  </p>
              </template>
          </PostBodyIntroduction>

          <PostBodySection>
            <template #title>
              В какую школу записать ребенка. 
            </template>
            <template #content>
                <p>
                    Система образования во Франции – одна из лучших в мире. В некоторых областях знаний страна занимает мировое господство. На сегодняшний день, обязательное образование во Франции начинается с 3-х летнего возраста и продолжается вплоть до 16 лет, хотя 50% учеников получают полное образование в возрасте 18 лет-21 года.
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Школьный год во Франции
            </template>
            <template #content>
                <p>
                    Учеба во Франции длится с сентября по июнь. Летом у детей два месяца каникул, еще 2-3 недели отдыха на Рождество и Пасху, а также другие перерывы между учебой. Время школьных каникул отличается в зависимости от региона проживания - страна поделена на 3 зоны. Это позволяет регулировать поток отдыхающих на курортах Франции.
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Школьная неделя во Франции  
            </template>
            <template #content>
                <p>
                    Обучение во Франции длится 24-28 часов в месяц, 4-5 дней в неделю, где среда, как правило, является выходным днем. Школьный день начинается в 8.30 утра и заканчивается в 16.30 дня. Есть возможность оставить ребенка на внеклассные занятия  и забрать к 18.30 вечера. Обед длится полтора часа. Ребенок может пойти домой, либо остаться на обед в школе. Школьное меню на неделю вывешивается на фасаде школы. Все большее место в нем занимают био-продукты, время от времени проводятся и тематические обеды, когда ученики пробуют блюда разных стран мира. Плата за обед пропорциональна доходам семьи и высчитывается индивидуально. Как и в России, учеба во Франции подразумевает домашние задания. В старших классах это может отнимать от 2-х и более часов. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Как записать ребенка в школу во Франции 
            </template>
            <template #content>
                <p>
                    Доступ к среднему образованию во Франции имеет каждый ребенок, вне зависимости от ситуации родителей. Чтобы записать ребенка в государственную школу во Франции, необходимо обратиться в Мэрию по месту жительства. Запись начинается в декабре и заканчивается в конце февраля, после этого сертификат о записи необходимо отнести в школу и встретиться с директором. В особых случаях – например, в связи с переездом, записать в школу ребенка можно и в другие месяцы. Для детей иностранных граждан часто предусмотрены международные классы, где дети могут адаптироваться к учебе на французском языке, если они его пока не знают. Для записи ребенка а школу во Франции, вам потребуются всего лишь такие документы, как: квитанция за электричество или интернет по месту жительства, копия свидетельства о рождении ребенка и выписка о проведении необходимых во Франции вакцин. Все документы должны быть переведены на французский язык у официального присяжного переводчика. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Этапы среднего образования во Франции:
            </template>
            <template #content>
                <ul>
                    <li>
                        <p>
                            Подготовительная школа (École maternelle) – в нее идут дети от 3 до 6 лет. До этого можно отдать ребенка в ясли (créche). C 2020 года начальная школа с 3-х лет стала обязательной во Франции. Главная цель направлена на социализацию ребенка. Подготовительная школа во Франции – это больше, чем просто развлекательная площадка. В расписании есть чтение и письмо, счет и – в зависимости от школы – иностранные языки. В программу французского обучения также включены артистические и креативные кружки. 
                        </p>
                        <p>
                            В зависимости от возраста, детей распределяют в группы:  младшая» (les petits), средняя (les moyens), взрослая (les grands). В начальной школе во Франции дети учатся 4 раза в неделю, среда – выходной день. Если оба родителя работают, то ребенка можно отвести на дополнительные кружки по выбору или, например, записать в русскую школу. В Париже их достаточно много. 
                        </p>
                        <p>
                            Зачисление в школу во Франции зависит от места жительства. Многие родители специально выбирают определенные районы, чтобы попасть в желаемое учебное заведение. Что касается качества образования, то оно находится на высоком уровне как в государственных, так и в частных школах.
                        </p>
                    </li>
                    <li>
                        <p>
                            Начальная школа (École Elementaire или École Primaire) – идут дети от 6 до 11 лет. Система образования во Франции в начальной школе похожа на остальные программы, существующие во всем мире. Классы включают арифметику, письмо, литературу, географию, историю и искусство, а также – в большинстве случаев -  иностранный язык, чаще всего английский. С 2008 года дети в начальной школе больше не учатся по субботам, среда также выходной день. 
                        </p>
                        <p>
                            Во французской начальной школе классы делятся на несколько уровней, в зависимости от года обучения: CP – подготовительный класс, CE1, CE2 – начальный класс, CM1, CM2 – средний класс. Последние два являются подготовкой к переходу на новый уровень, в среднюю школу. Важно отметить, что система образования Франции построена таким образом, что школы не зависят друг от друга (Начальные и подготовительные школы часто бывают расположены рядом, но колледжи и лицеи, как правило, не связаны с ними и имеют собственное расположение и руководство). Обучение во Франции подразумевает и ежегодное перераспределение классов. Придя в сентябре вновь в школу, ребенок встретит в одной группе как бывших одноклассников, так и новых. Такое перераспределение позволяет более гармонично социализировать ребенка, адаптировать его к подростковой и взрослой жизни. 
                        </p>
                    </li>
                    <li>
                        <p>
                            Колледж (Collège): средняя школа. Четыре уровня, куда принимаются школьники от 11 до 15 лет. Основная задача колледжа – предоставить  образование общей направленности, без определенной специализации. На практике же, во время обучения в колледже ученики ориентируются или на предметы общей направленности и идут в последствии в традиционный лицей, либо на более техническое обучение, для этого они сдают экзамен, который называется “brevet”. После его сдачи, подростки могут либо закончить образование (если достигли нужного возраста), либо продолжить учиться в профессиональном лицее/училище. 
                        </p>
                        <p>
                            Программа образования в колледже во Франции включает в себе такие предметы, как: математика, история, французский язык, география, техническое обучение, искусство и музыка, физкультура, обществознание, другие точные науки и как минимум 1 иностранный язык. Классы в колледже идут по шкале от 6 до 9 и имеют названия (sixième, cinquième, quatrième, troisième). 
                        </p>
                    </li>
                    <li>
                        <p>
                            Старшая школа или Лицей (Lycée). Это последние три класса средней школы. Обучение во Франции в лицее делится на 2 направления: lycée général or lycée classique – классический лицей, либо lycée technique – технический лицей. Такое разделение, как правило, можно видеть в больших городах. В провинции эти два лицея часто объединены. В технических лицех ученики изучают узкоспециализированные предметы, например микротехнологии или аэронавтику.
                        </p>
                        <p>
                            Главная функция последних классов средней школы – подготовить учеников к сдачи экзамена baccalauréat (bac), его часто сравнивают с российским ЕГЭ. Как правило, предметы в лицее точно такие же, как и в колледже, за исключением философии, которая идет в дополнение. Три класса, по шкале от 10-12, имеют названия seconde, première и terminale. В теории, все лицеи Франции должны предоставлять одинаковое по качеству образование. На практике же, ежегодно публикуются рейтинги лицеев с самыми высокими показателями, в это список почти всегда попадают Lycée Louis-le-Grand or Lycée Henri-IV в Париже,  Lycée Fermat в Тулузе и другие популярные государственные лицеи. Тем не менее, все чаще и чаще в этот список стали попадать и частные лицеи – например, Collège Stanislas в Париже, который является самым большим частным лицеем во Франции, в 2020 году он занял первое место в топ-30 лицеев во Франции по версии газеты Figaro. Рейтинги лицеев ежегодно публикуют такие авторитетные издания, как l'Etudiant и Figaro. 
                        </p>
                        <p>
                            Профессиональное училище (Lycée professionnel или Lycée PRO): предоставляет по существу неакадемическую программу для молодых людей, намеревающихся быть задействованными в сфере ручного труда или на канцелярских работах. Ученики будут либо работать в направлении сдачи «baccalauréat professionalnel» (bac pro), для которого им нужно будет продолжать посещать занятия по основным предметам - французскому языку, математике и иностранному языку, BEP (Brevet d'enseignement professionalnel) или CAP (сертификат аттестата профессионализма). Одним из распространенных типов профессиональных училищ, который можно найти в большинстве городов, является «лицей ремесел» или лицей строительных специальностей, в котором учащиеся специализируются на одной из многочисленных областей строительной отрасли. Есть также большое количество сельскохозяйственных средних школ и даже садоводческих вузов, обеспечивающих техническую подготовку, необходимую для будущих фермеров и садоводов.
                        </p>
                    </li>
                </ul>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Топ-30 лицеев Франции в 2020 году (Le Figaro)
            </template>
            <template #content>
                <p>
                    В систему составления рейтинга входят такие критерии, как успешность сдачи экзамена BAC, индекс упоминания и другие факторы.
                </p>
                <p>
                    1. Lycée Stanislas, Paris 6 <br>

                    2. Lycée Saint-Louis de Gonzague, Paris 16 <br>

                    3. Lycée Madeleine Danielou, Rueil-Malmaison (92) <br>

                    4. Lycée Saint-Jean de Passy, Paris 16 ème <br>

                    5. Ecole active Jeanine Manuel, Paris 15 <br>

                    6. Lycée franco-allemand, Buc (78) <br>

                    7. Lycée Henri IV, Paris 5 ème <br>

                    8. Lycée Sainte-Marie de Neuilly (92) <br>

                    9. Lycée international de Saint-Germain en Laye (78) <br>

                    10. Lycée Condorcet, Paris 9 ème <br>

                    11. Lycée Epin, Vitry sur Seine (94) <br>

                    12. Lycée privé de Marcq en Bareuil (59) <br>
 
                    13. Lycée Blomet, Paris 15 ème <br>

                    14. Lycée Pascal, Paris 16 ème <br>

                    15. Lycée Stanislas, Nice <br>

                    16. Lycée Notre-Dame, Boulogne-Billancourt (92) <br>

                    17. Lycée Saint-Michel de Picpus, Paris 12 ème <br>

                    18. Lycée Fénelon Sainte Marie, Paris 8 ème <br>

                    19. Lycée Notre-Dame de France, Paris 13 ème <br>

                    20. Lycée Saint-François d’Assise, Montigny-le Bretonneux (78) <br>

                    21. Lycée Saint-Dominique, Neuilly-sur-Seine (92) <br>

                    22. Institution des Chartreux, Lyon 1 er <br>

                    22. Lycée Louis Le Grand, Paris 5 ème <br>

                    24. Lycée Pontlevoy, Pontlevoy <br>

                    25. Ecole internationale bilingue, Paris 17 ème <br>

                    26. Lycée de l’Alma, Paris 7 ème <br>

                    27. Lycée Stanislas, Saint-Raphaël <br>

                    28. Lycée du Mont Saint-Jean, Antibes <br>

                    29. Lycée de l’Ermitage, Maisons Lafitte (78) <br>

                    30. Lycée Sainte-Jeanne Elisabeth, Paris 7 ème <br>
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Государственные и Частные школы во Франции.
            </template>
            <template #content>
                <p>
                    Система образования Франции сегодня насчитывает около 20% частных школ и 80% государственных. При этом, 90% частных школ – это католические школы. Плата за обучение в них достаточно демократичная, при этом определенное время отводится на изучение религии. В государственных школах этот предмет, как правило, отсутствует. На качество образования не влияет тот факт, если школа относится к частным или государственным, все они следуют единой системе образования, и зарплату учителем выплачивает государство. Более дорогие школы – это биллингвальные школы, либо школы по системе Монтессори. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Система оценок.
            </template>
            <template #content>
                <p>
                    Что касается системы оценивания знаний, то она строится по шкале 10/20, где 10 – минимальная необходимая оценка для сдачи и 20 – высшая. Как правило, получить последнюю практически нереально. Во Франции 14-15 баллов уже считается хорошей оценкой. Если рассматривать более подробно: 
                </p>
                <ul>
                    <li>10-11.99 : удовлетворительно</li>
                    <li>12-13.99 : достаточно хорошо</li>
                    <li>14-15.99 : хорошо</li>
                    <li>16 – очень хорошо</li>
                </ul>
                <p>
                    Если выпускник получил от 8 до 10 баллов во время сдачи экзамена BAC, он пересдает его через 2 недели. 
                </p>
            </template>
          </PostBodySection>

          <PostBodySection>
            <template #title>
              Домашнее обучение во Франции
            </template>
            <template #content>
                <p>
                    Вполне возможно. Для этого, нужно взять разрешение в мэрии. Тем не менее, раз в год необходимо проходить экспертизу школьного инспектора, который оценит, если на домашнем обучении ребенок успешно прошел ту же программу, что и другие дети его возраста проходят в школе. 
                </p>
            </template>
          </PostBodySection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import PostHeader from '@/components/blog/slots/PostHeader.vue'
import PostBodyIntroduction from '@/components/blog/slots/PostBodyIntroduction.vue'
import PostBodySection from '@/components/blog/slots/PostBodySection.vue'
import SubNav from '@/components/common/slots/SubNav.vue'
export default {
  name: 'BlogPost5',
  components: {
    Navbar,
    PostHeader,
    PostBodyIntroduction,
    PostBodySection,
    SubNav
  }
}
</script>
